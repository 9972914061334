import React from "react"
import { Link, graphql } from "gatsby"
import Img from 'gatsby-image'
import { Container, Row, Col } from "react-bootstrap"

import { BLOCKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PageTitle from "../components/PageTitle/PageTitle"
import ContentBlock from "../components/ContentBlock/ContentBlock"
import KeelLaying from "../components/ConstructionPage/KeelLaying"
import Christening from "../components/ConstructionPage/Christening"

import NewsCardsSidebar from "../components/NewsBlock/NewsCardsSidebar"
import EventsCardsSidebar from "../components/EventsBlock/EventsCardsSidebar"
import Commissioning from "../components/ConstructionPage/Commissioning"
import CommissioningPennant from "../components/ConstructionPage/CommissioningPennant"
import PathToCommissioning from "../components/ConstructionPage/PathToCommissioning"

const ConstructionCommissioning = ({ data }) => {
  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: ({
        data: {
          target: { fields },
        },
      }) => (
        <div
          dangerouslySetInnerHTML={{
            __html: `<img src="${fields.file["en-US"].url}" alt="${fields.title["en-US"]}" class="fluid" style="width: 100%; margin: 20px 0 20px 0" />`,
          }}
        />
      ),

      [BLOCKS.EMBEDDED_ENTRY]: node => {
        const fields = node.data.target.fields.imageLink
        const imgLnk = node.data.target.fields.url
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: `<a href="${imgLnk["en-US"]}"><img src="${fields["en-US"].fields.file["en-US"].url}" alt="${fields.title}" style="width: 100%;" /></a>`,
            }}
          />
        )
      },
    },
  }

  return (
    <Layout>
      <SEO title="About Us" />
      <Container fluid className="container-fluid-full">
        <PageTitle subPage="Construction &amp; Commissioning" pageTitle="Construction &amp; Commissioning" link="/">
          {/* {page.pageTitle} */}
        </PageTitle>
        <ContentBlock>
          <Row className="mb-4">
            <Col sm={9}>
              <div id="contstruction-phases" className="jumpTarget">
                <h1>Construction Phases</h1>
                <p>It takes about five years to build a Virginia class submarine. The submarines are built in a teaming arrangement by General Dynamics-Electric Boat and Huntington Ingalls Newport News Shipbuilding. The latter builds the stern, habitability and machinery spaces, torpedo room, sail and bow sections. Electric Boat builds the engine room and control room. Both contractors perform work on the reactor plant and alternate on the final assembly, test, outfitting and delivery. After the modules are constructed they are barged to the final construction yard, which for USS IDAHO is Electric Boat Shipyard in Groton, CT. There the modules are assembled and systems are tested.</p>

                <h2>Preparing for Commissioning</h2>
              <p>Many milestones are completed between launching and commissioning of a ship. Below are just a few of them.</p>
                {/* <PathToCommissioning /> */}
              </div>
              <div id="keel-laying" className="jumpTarget">
                <h2>Keel Laying - 24 AUG 2020</h2>
                <p>This is the formal recognition of the start of a ship's construction. In earlier times it was the "laying down" of the central or main timber making up the backbone of a vessel. Today, fabrication of the ship may begin months before and some of the ship's bottom may actually be joined. However, the keel laying ceremony (also referred to as the keel authentication ceremony) symbolically recognizes the joining of modular components and the ceremonial beginning of a ship.</p>
                <p>During the keel laying ceremony, the ship's sponsor authenticates the keel by chalking her initials onto a metal plate. The initials are then welded onto a plate that is permanently affixed to the ship.</p>
              {/* <KeelLaying /> */}
              </div>
              <div id="christening" className="jumpTarget">
                <h2>Christening - Spring 2024</h2>
                <p>The official launching ceremony recognizing the "floating" of a ship by name and marked with the traditional breaking of a bottle of champagne across the bow.</p>
                <p>The blessing of ships dates as far back as the third millennium BC, when the ancient Babylonians, according to a narrative, sacrificed an oxen to the gods upon completion of a ship. Throughout history, different cultures developed and shaped the religious ceremony surrounding a ship launching.</p>
                <p>Today the christening is often conducted before the launching. The ship's sponsors who are most often women break the bottle of champagne and ceremonially give the ship its name. The first recorded christening of a United States Navy ship is USS Constitution, on Oct. 21, 1797 in Boston, where the ship's sponsor, Capt. James Sever, broke a bottle of wine across the bow as "Old Ironsides" slid into the water.</p>
                <p>If you are interested in attending the Christening or Commissioning, please periodically check our <Link to="/upcoming-events">Upcoming Events page</Link>. We will post updates there when more information is available.</p>
              </div>

              <div id="sea-trials" className="jumpTarget">
                <h2>Sea Trials</h2>
                <p>Sea trials are an intense series of tests to demonstrate the satisfactory operation of all installed shipboard equipment. Sea Trials ensure that the performance of the ship as a whole is in accordance with its plans and specifications. New construction ships undergo Builder's Trials and Acceptance Trials prior to ship's delivery and Final Contract Trials several months after delivery and sail away.</p>
              </div>

              <div id="delivery" className="jumpTarget">
                <h2>Delivery</h2>
                <p>The official turnover of custody of a ship from the shipyard to the U.S. Navy. This private ceremony involves the Prospective Commanding Officer who actually signs for the ship. This event normally coincides with Move Aboard when the Pre-commissioning crew moves aboard and starts living, eating, standing watch, training and working aboard the ship while final work continues in the shipyard.</p>
              </div>

              <div id="sail-away" className="jumpTarget">
                <h2>Sail Away</h2>
                <p>The ship's final departure from the construction yard for its homeport or commissioning site. It signifies the end of the new construction period and the beginning of its life preparing to perform the mission it was designed to undertake.</p>
              </div>

              <div id="commissioning" className="my-4 jumpTarget">
                 {/* <Commissioning /> */}
                 <h2>Commissioning - Spring 2025</h2>
                 <p>The commissioning ceremony marks the acceptance of a ship as a unit of the operating forces of the United States Navy. At the moment of breaking the commissioning pennant, the ship will "come alive" and the crew will ceremonially run aboard ship. Thereafter the ship is officially referred to as a United States Ship (USS).</p>
                 <p>The act of placing a ship in commission marks her entry into active Navy service. At the moment when the commissioning pennant is broken at the masthead, a ship becomes a Navy command in her own right and takes her place alongside the other active ships of the Fleet.</p>
                <p>This ceremony continues a centuries old tradition, observed by navies around the world, and by our own Navy since December 1775, when Alfred, the first ship of the Continental Navy, was commissioned at Philadelphia. Once in commission, the commanding officer and crew are entrusted with the privilege, and the responsibility, of maintaining their ship’s readiness in peace, and of conducting successful operations at sea in time of war.</p>
                <p>No written procedure for commissioning was laid down in our Navy’s early days, but the act of commissioning was familiar, derived from established British naval custom. Commissionings were simple military ceremonies. The prospective commanding officer came on board, called the crew to quarters, and formally read the orders appointing him to command. He then ordered the ensign and the commissioning pennant hoisted; at that moment the ship went into commission, and the first entry in the ship’s deck log recorded this.</p>
               </div>
              
              <div id="keel-laying" className="jumpTarget">
              <KeelLaying />
              
              </div>              
               
               <div id="christening" className="jumpTarget">
                 {/* <Christening /> */}
               </div>
               
               <div id="commissioning-pennant" className="jumpTarget">
                 <CommissioningPennant />
               </div>
            </Col>
            <Col sm={3}>
              <h1>USS IDAHO News</h1>
              <NewsCardsSidebar />
              <h1>USS IDAHO Events</h1>
              <EventsCardsSidebar />

            </Col>
          </Row>
        </ContentBlock>
      </Container>
    </Layout>
  )
}

// export const query = graphql`
// {
//   allContentfulGallery (filter: {galleryTitle: {eq: "Keel Laying"}}) {
//     edges {
//       node {
//         images {
//           description
//           fluid (maxWidth: 500) {
//             srcWebp
//           }
//         }
//       }
//     }
//   }
// }
// `

export default ConstructionCommissioning
