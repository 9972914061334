import React from 'react'
import { Col } from 'react-bootstrap'
import {useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import ModalImage from 'react-modal-image'
import styled from 'styled-components'

const GalleryGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  width: 100%;
`;


const KeelLayingGallery = () => {
    const query = useStaticQuery(graphql`
    {
        allContentfulGallery (filter: {galleryTitle: {eq: "Keel Laying"}}) {
          edges {
            node {
              images {
                description
                fluid (maxWidth: 500) {
                  srcWebp
                }
              }
            }
          }
        }
      }
  `)
  const photos = query.allContentfulGallery.edges[0].node.images;
  console.log(photos)

    return (
      <GalleryGrid>
      { photos.map((photo) => {
        return (
          <Col>
            <div>
            <img src={photo.fluid.srcWebp} alt="USS IDAHO Keel Laying" className="rounded" style={{width: '100%', height: '100%'}}/>            
            <p style={{fontSize: 'smaller'}}>{photo.description}</p>
            </div>
          </Col>
        )
      })}
      {/* <GalleryGrid>
              { photos.map((photo) => {
       return (         
           <ModalImage
            hideDownload
            hideZoom 
            small={photo.fluid.srcWebp}
            large={photo.fluid.srcWebp}
            alt={photo.description}
            className="rounded"
           />
          
       )
     }) }
     </GalleryGrid> */}
     </GalleryGrid>
    )
}

export default KeelLayingGallery