import React from 'react'
import {useStaticQuery, graphql } from 'gatsby'
import { Row, Col } from 'react-bootstrap'
import KeelLayingGallery from './KeelLayingGallery'
import { BLOCKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

const KeelLaying = () => {
    const data = useStaticQuery(graphql`
    {
      allContentfulConstructionCommissioningSections(filter: {contentful_id: {eq: "6yE75UQ0oSLY4sR93xDXjr"}}) {
        edges {
          node {
            sectionTitle
            childContentfulConstructionCommissioningSectionsSectionContentRichTextNode {
              json
            }
          }
        }
      }
    }
  `)

  const KeelLayingSection = data.allContentfulConstructionCommissioningSections.edges[0].node

    const options = {
        renderNode: {
          [BLOCKS.EMBEDDED_ASSET]: ({
            data: {
              target: { fields },
            },
          }) => (
            <div
              dangerouslySetInnerHTML={{
                __html: `<img src="${fields.file["en-US"].url}" alt="${fields.title["en-US"]}" class="fluid" style="width: 100%; margin: 20px 0 20px 0" />`,
              }}
            />
          ),
    
          [BLOCKS.EMBEDDED_ENTRY]: node => {
            const fields = node.data.target.fields.imageLink
            const imgLnk = node.data.target.fields.url
            return (
              <div
                dangerouslySetInnerHTML={{
                  __html: `<a href="${imgLnk["en-US"]}"><img src="${fields["en-US"].fields.file["en-US"].url}" alt="${fields.title}" style="width: 100%;" /></a>`,
                }}
              />
            )
          },
        },
      }

    return (
        <>
        <Row style={{
            backgroundColor: '#426690',
            borderRadius: '5px',
            padding: '2rem',
        }}>
            <Col>
            
            <div className="white-text" style={{color: 'white !important'}}>
              <h1 className="whiteText">Keel Laying</h1>
                  {documentToReactComponents(
                      KeelLayingSection
                      .childContentfulConstructionCommissioningSectionsSectionContentRichTextNode
                      .json,
                    options
                  )}
                </div>

            </Col>
        </Row>
        <Row className="my-4">
            <KeelLayingGallery />
        </Row>
        </>
    )
}

export default KeelLaying