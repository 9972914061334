import React from 'react'
import {useStaticQuery, graphql } from 'gatsby'
import { Row, Col } from 'react-bootstrap'
import { BLOCKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Img from 'gatsby-image'
import { faPennant } from '@fortawesome/pro-solid-svg-icons'

const CommissioningPennant = () => {
    const data = useStaticQuery(graphql`
    {
      allContentfulConstructionCommissioningSections(filter: {sectionTitle: {eq: "Commissioning Pennant"}}) {
        edges {
          node {
            sectionTitle
            childContentfulConstructionCommissioningSectionsSectionContentRichTextNode {
              json
            }
          }
        }
      }
        pennant: file(relativePath: { eq: "commissioning_pennant.png" }) {
         childImageSharp {
          fluid(quality: 80) {
           ...GatsbyImageSharpFluid
          }
         }
        }
    }
  `)

  const CommissioningPennant = data.allContentfulConstructionCommissioningSections.edges[0].node

    const options = {
        renderNode: {
          [BLOCKS.EMBEDDED_ASSET]: ({
            data: {
              target: { fields },
            },
          }) => (
            <div
              dangerouslySetInnerHTML={{
                __html: `<img src="${fields.file["en-US"].url}" alt="${fields.title["en-US"]}" class="fluid" style="width: 100%; margin: 20px 0 20px 0" />`,
              }}
            />
          ),
    
          [BLOCKS.EMBEDDED_ENTRY]: node => {
            const fields = node.data.target.fields.imageLink
            const imgLnk = node.data.target.fields.url
            return (
              <div
                dangerouslySetInnerHTML={{
                  __html: `<a href="${imgLnk["en-US"]}"><img src="${fields["en-US"].fields.file["en-US"].url}" alt="${fields.title}" style="width: 100%;" /></a>`,
                }}
              />
            )
          },
        },
      }

    return (
        <>
        <Row style={{
            backgroundColor: '#426690',
            borderRadius: '5px',
            padding: '2rem',
        }}>
            <Col>
            
            <div className="white-text" style={{color: 'white !important'}}>
              <h1 className="whiteText mb-4">Commissioning Pennant</h1>
              <Row>
                <Col sm={6}>
                  <Img fluid={data.pennant.childImageSharp.fluid} alt="Commissioning Pennant" />
                </Col>
                <Col sm={6} className="white-text">
                {documentToReactComponents(
                      CommissioningPennant
                      .childContentfulConstructionCommissioningSectionsSectionContentRichTextNode
                      .json,
                    options
                  )}
                </Col>
              </Row>
                  
                </div>

            </Col>
        </Row>
        </>
    )
}

export default CommissioningPennant